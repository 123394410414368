import React from "react"
import PropTypes from "prop-types"
import { formatRawCredentialString } from "../Helper"
import { formatAccessCredential } from "../Helper"
class Authorization extends React.Component {
  constructor(props) {
    super(props);
    this._last_sqs_message = null;
    this.state = {
      authorization: null,
      isLoading: false,
      errorMessage: null,
      web_aws_access_key_id: props.web_aws_access_key_id,
      web_aws_secret_access_key: props.web_aws_secret_access_key,
      aws_env_name: props.aws_env_name,
      reader_id: props.reader_id,
      garage_id: props.garage_id,
      reader_name: props.reader_name,
      dashboard_session_id: props.dashboard_session_id
    };
    this.handleReaderClick = this.handleReaderClick.bind(this);
    this.fetchAuthorization = this.fetchAuthorization.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  handleReaderClick(event) {
      const index = event.target.href.split("#")[1];
      console.log("handleReaderClick with index "+index)
      var garage_id = this.state.garage_id
      const reader_id = this.state.authorization.readers[index].id;
      console.log("handleReaderClick with reader_id "+reader_id)
      console.log("Fetch called from handleReaderClick")
      this.fetchAuthorization(reader_id, garage_id);
  }

  render () {
    console.log("start render")
    var header = "";
    if (this.state.errorMessage) {
      header = this.state.errorMessage;
    }

    var readersHeader = (<p>No gates available</p>)
    if (this.state.authorization != null && this.state.authorization.readers != null) {
      var readerElements = []
      for (var i = 0; i < this.state.authorization.readers.length; i++) {
        const reader = this.state.authorization.readers[i]
        readerElements.push ( <span key={i}><a href={'#'+i} onClick={this.handleReaderClick}>{reader.name}</a> {i < this.state.authorization.readers.length-1 ? "|": ""} </span> )
      }
      readersHeader = (<p>{readerElements}</p>)

    }
    if (this.state.authorization != null && this.state.authorization.reasoning != null) {
      const reasoning = JSON.parse(this.state.authorization.reasoning);

      var authorized = <h2 className="authorized">Authorized</h2>
      if (this.state.authorization.authorized_credential == null) {
        authorized = <h2 className="not-authorized">Not Authorized</h2> 
      }
      if (this.state.authorization.authorized_permit_identifier != null) {
        authorized = <h2 className="authorized">Authorized by permit {this.state.authorization.authorized_permit_identifier}</h2> 
      }
      
      var permitHeader = ( <h3>No Permits</h3> ) 
      var reason = "NO CREDENTIAL"
      if (reasoning) {
        reason = reasoning.reason;
        if (reasoning.permit_reasons.length > 0) {
          permitHeader = ( <h3>Evaluated Permits:</h3> ) 
        }
        var permitRows = [];
        for (var i = 0; i < reasoning.permit_reasons.length; i++) {
          var className = reasoning.permit_reasons[i].reason == "permit_active_and_unused" ? "authorized" : "not-authorized"
          permitRows.push ( <p key={i} className={className}>{reasoning.permit_reasons[i].permit_identifier}: {reasoning.permit_reasons[i].reason}</p> )
        }
        var hourlyInfo = null;
        var hourlyHeader = ( <p>No hourly information applicable</p> );
        if (reasoning.hourly_info) {
          hourlyHeader = ( <h3>Hourly Info:</h3> )
          hourlyInfo = (
            <div>
            <p className={reasoning.hourly_info.hourly_allowed ? "authorized" : "not-authorized"}>Hourly Enabled for Garage: {reasoning.hourly_info.hourly_allowed ? "YES" : "NO"}</p>
            <p className={reasoning.hourly_info.sufficient_funds ? "authorized" : "not-authorized"}>Sufficient Funds: {reasoning.hourly_info.sufficient_funds ? "YES" : "NO"}</p>
            <p className={reasoning.hourly_info.handsfree_autopay ? "authorized" : "not-authorized"}>Handsfree Autopay Enabled: {reasoning.hourly_info.handsfree_autopay ? "YES" : "NO"}</p>
            </div>
          )
        }  
      }
      var formattedCredential = this.state.authorization.credential ? formatRawCredentialString(this.state.authorization.credential, this.state.authorization.credential_type) : "NONE";
      console.log("returning from render")
      return (
        <React.Fragment>
        <div id="authorization" className={this.state.isLoading ? "loading" : "done-loading"}>
        {header}
        {readersHeader}
        <h1>{this.state.reader_name} <button style={{fontSize: "15px"}}  onClick={this.refresh}>Refresh</button></h1>
        <h3 style={{fontWeight: "bold"}}>Recent authorization attempt:</h3>

        <p>Loop 1 detected at: {this.state.authorization.detected_at}</p>
        <p>Gate opened at: {this.state.authorization.gate_opened_at}</p>
        <p>Passed Gate at: {this.state.authorization.passed_gate_at}</p>
        <p/>
        <h3 style={{fontWeight: "bold"}}>Identified Member:</h3>
        <p>Credential: {formattedCredential}</p>
        <p>Member ID: {this.state.authorization.member_id ?  this.state.authorization.member_id  : "-"}</p>
        <p>Name: {this.state.authorization.name ?  this.state.authorization.name  : "UNKNOWN"}</p>
        <p/>
        <h3 style={{fontWeight: "bold"}}>Vehicle Info:</h3>
        <p>Vehicle: {this.state.authorization.vehicle ? this.state.authorization.vehicle : "UNKNOWN"}</p>
        <p>LPR: {this.state.authorization.lpr_plate || "NONE"}</p>

        <hr/>
        <p style={{fontWeight: "bold"}}>Authorization Results:</p>

        {authorized}
        {reason}
        {permitHeader}
        {permitRows}
        {hourlyHeader}
        {hourlyInfo}
        </div>
        </React.Fragment>
      );
    }
    else {
    return (
        <React.Fragment>
        <div id="authorization" className={this.state.isLoading ? "loading" : "done-loading"}>
        {header}
        {readersHeader}
        <h1>{this.state.reader_name}</h1>
        <p>No recent authorization attempt</p>
        </div>
        </React.Fragment>
      );
    }
  }

  componentDidMount() {
    console.log("component mount start")
    var garage_id = this.state.garage_id
    const reader_id = this.state.reader_id
    if (garage_id == null) {
      garage_id =  window.location.pathname.split("/")[2]
      console.log("no garage set -- setting based on window value "+window.location.pathname.split("/")[2].split("#")[0])
    }
    console.log("Fetch called from componentDidMount")
    this.fetchAuthorization(reader_id, garage_id);
    this.watchForNextAuthorization(garage_id);  
    console.log("component mount end")
  }

  refresh() {
    this.fetchAuthorization(null, null)
  }
  fetchAuthorization(in_reader_id, in_garage_id) {
    console.log("in_reader_id "+in_reader_id)
    console.log("this.state.reader_id "+this.state.reader_id)
    const reader_id = in_reader_id || this.state.reader_id
    const garage_id = in_garage_id || this.state.garage_id
    console.log("fetching authorization for "+reader_id)
    var that = this;
    var url = window.location.protocol+"//"+window.location.host+"/api/v2/authorization/"+reader_id+'?garage_id='+garage_id
    var responseAfterPromise = null;
    console.log('start fetch '+url);
    that.setState({isLoading: true, errorMessage: null});
    console.log('start fetch2 '+url);
    fetch(url)
      .then(res => {responseAfterPromise = res; return res.json();})
	    .then(json => {
          console.log('end fetch: '+json);
          if (responseAfterPromise.status === 200) {
            that.setState({authorization: json, isLoading: false, errorMessage: null, reader_id: reader_id, reader_name: json.reader_name});
            console.log('end fetch state change');
            return Promise.resolve(json)
          }
          var errorMessage = responseAfterPromise.statusText;
          if (json.errors && typeof json.errors === 'object' && json.errors.constructor === Array && json.errors.length > 0) {
            errorMessage = json.errors.join("; ");
          }
          return Promise.reject(new Error(errorMessage))
	    })
	    .catch(function(error) {
  	      var errorString = ""+error;
          that.setState({isLoading: false, errorMessage: ''+error});
        });
  }

  watchForNextAuthorization(garage_id) {
    console.log("starting sqs watch");
    var sqs = new AWS.SQS({
      apiVersion: '2012-11-05',
      accessKeyId: this.state.web_aws_access_key_id,
      secretAccessKey: this.state.web_aws_secret_access_key,
      region: 'us-east-1'
     });
    var params = {
      QueueName: aws_env_name+"_transit_events_for_garage_"+garage_id+"_"+this.props.dashboard_session_id
    };
    var that = this;
    console.log("Getting queue for "+JSON.stringify(params));
    sqs.getQueueUrl(params, function(err, data) {
       if (err) {
         console.log("cannot get SQS queue URL for "+params+" due to error: "+err);
       }
       else {
         var params  =  {'QueueUrl': data['QueueUrl'], 'WaitTimeSeconds': 1};
         sqs.receiveMessage(params, function(err, data) {
           if (err) {
             console.log("Error polling SQS: "+err);
             console.log("SQS Queue name: "+params.QueueName);
           }
           else {
             console.log("Message count: "+ data['Messages'].length);
             if (data['Messages'].length > 0) {
               var sqsMessage = data['Messages'][0]['Body'];
                console.log("SQS message changed from "+that._last_sqs_message+" to "+sqsMessage);
                that._last_sqs_message = sqsMessage;
                console.log("Fetch called from SQS monitor")
                that.fetchAuthorization();
             }
             that.watchForNextAuthorization(garage_id);
           }
         });
       }
    });
  }

}

Authorization.propTypes = {
  greeting: PropTypes.string
};
export default Authorization
