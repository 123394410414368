/**
 * Converts api value to a human readable value (ie. hourly_no_permit to Hourly: No Permit) 
 * 
 * @param {string} raw_entry_mechanism Accepts raw_entry_mechanism value from API/db (ie. ticket_no_permit)
 * @return {string} Human readable version: (ie. Ticket: No Permit)
 */
const transformEntryMechanism = (raw_entry_mechanism) => {
  if (typeof raw_entry_mechanism === "string") {
    return raw_entry_mechanism.split("_").map( w =>  w.substring(0,1).toUpperCase()+ w.substring(1)).join(" ").replace(" ", ": ");
  } else {
    return "";
  }
}

const formatRawCredentialString = (credential, credential_type) => {
  if (credential_type === "mobile") {
    return "M "+credential;

  } else if (credential_type === "rfid") {
    return credential.replace("C033 ", "");
  } else if (credential_type === "qr") {
    if (credential.startsWith("COMV") || credential.startsWith("COMS")) {
      return "Q "+credential.substring(3,4)+credential.substring(4).replace("-", " ");
    }
    else {
      // Remove COM- from QR code and then only shot a subset of the long string
      // for instance COM80-1a2b3c4d5678910111213 -> `1A2B 3C4D`
      return "Q "+credential.substring(credential.indexOf("-")+1).substring(0, 8).toUpperCase().replace(/^(\w{4})/g, '$1 ');
    }
  } else if (credential_type === "fob") {
    const identifier = credential.slice(3);
    return `F ${parseInt(identifier, 16)}`;
  } else if (credential_type === "phone") {
    return `P${credential}`;
  } else {
    return credential;
  }
}

const formatAccessCredential = (transit) => {
  if (transit.mobile_device_id) {
    return formatRawCredentialString(transit.credential, "mobile");
  }
  else if (transit.credential) {
    if (transit.credential.startsWith("C033")) {
      return formatRawCredentialString(transit.credential, "rfid");
    } else if (transit.credential.startsWith("COM")) {
      return formatRawCredentialString(transit.credential, "qr");
    } else if (transit.credential.startsWith("+")) {
      return formatRawCredentialString(transit.credential, "phone");
    } else {
      return formatRawCredentialString(transit.credential, "fob");
    }
  } else {
    return "";
  }
};

//  Should be equivalent of Digest::SHA256.hexdigest(string) in Ruby
const digestMessage = async (message) => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
  return hashHex;
}

export { digestMessage, transformEntryMechanism, formatAccessCredential, formatRawCredentialString };