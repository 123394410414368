import React from "react";
import EntryExitTableRow from "./EntryExitTableRow.js";
import { formatAccessCredential } from "../Helper"
import _ from "lodash";
import { find } from "lodash";
import classNames from "classnames";

class EntryExitTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transits: [],
      excludingTransients: this.getExcludingTransientState()
    };

    this.updateTransitView = this.updateTransitView.bind(this);
    this.loadMoreTransits = this.loadMoreTransits.bind(this);
    this.handleTransientToggle = this.handleTransientToggle.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  getExcludingTransientState() {
    const booleanState = JSON.parse(
      window.localStorage.getItem("excludingTransients")
    );
    if (booleanState === null) {
      return true;
    } else {
      return booleanState;
    }
  }

  async componentDidMount() {
    this.fetchAndSetTransits();
  }

  async fetchTransits() {
    const queryParamString = new URLSearchParams({
      exclude_transients: this.state.excludingTransients
    });
    return fetch(
      `${window.location.pathname}/transits?${queryParamString}`
    ).then(response => response.json());
  }

  async fetchAndSetTransits() {
    const transits = await this.fetchTransits();
    this.setState({ ...this.state, transits: transits });
  }

  async updateTransitView(garageId, transitId) {
    window.getTransit(garageId, transitId);
  }

  async handleNewTransits(transits) {
    console.log("Got new transit in React: "+JSON.stringify(transits[0]))
    const newTransits = [...transits, ...this.state.transits];
    this.setState({ ...this.state, transits: newTransits });
  }

  async handleTransientToggle(e) {
    const newState = !this.state.excludingTransients;
    window.localStorage.setItem("excludingTransients", newState);
    this.setState({ ...this.state, excludingTransients: newState }, () => {
      this.fetchAndSetTransits();
    });
  }

  async handleLoadMore(e) {
    this.loadMoreTransits()
  }

  // Not sure if we'll need this anywhere, could be good add
  removeCorrespondingEntries(transits) {
    return _.reject(transits, (transit, _, collection) => {
      if (transit.transit_type === "entry") {
        const correspondingExit = find(
          collection,
          t => t.entry_type === "exit" && t.entry_id === transit.id
        );

        if (correspondingExit) {
          return true;
        } else {
          return false;
        }
      }
    });
  }

  async loadMoreTransits() {
    const lastTransit = _.last(this.state.transits);
    const maxId = 2147483647;
    const lastTransitId = lastTransit ? lastTransit.id : maxId;

    const queryParamString = new URLSearchParams({
      last_transit_id: lastTransitId,
      exclude_transients: this.state.excludingTransients
    });
    const newTransits = await fetch(
      `${window.location.pathname}/transits/load_more?${queryParamString}`
    ).then(response => response.json());

    this.setState({
      ...this.state,
      transits: [...this.state.transits, ...newTransits]
    }, () => {
      document.querySelector(`[data-transit-id="${lastTransitId}"]`)?.scrollIntoView();
    });
  }

  render() {
    const { transits } = this.state;

    return (
      <>
        <button
          onClick={this.handleTransientToggle}
          id="include-transients"
          className={classNames("btn", "btn-default", {"show-unknown": !this.state.excludingTransients})}
          style={{ position: "absolute", top: "-70px", right: "15px" }}
        >
          {this.state.excludingTransients ? "Show Unknown" : "Hide Unknown"}
        </button>
        <div className="table-wrapper scrollable">
          <table className="table table-hover" id="transit-view">
            <thead>
              <tr>
                <th>Date/Time In</th>
                <th>Date/Time Out</th>
                <th>Plate #</th>
                <th>Access</th>
                <th>Duration</th>
                <th>Fee</th>
                <th>Transit Type</th>
                <th>Transaction ID</th>
              </tr>
            </thead>
            <tbody className="transit-list" id="transit-list">
              {transits.map((transit, _index) => (
                <EntryExitTableRow
                  key={transit.id}
                  duration_in_time={transit.formatted_duration}
                  entry_mechanism={transit.entry_mechanism}
                  local_entry_time={transit.formatted_entry_time}
                  local_exit_time={transit.formatted_exit_time}
                  garage_id={transit.garage_id}
                  payment={transit.payment}
                  transaction_id={transit.transaction_id}
                  transit_id={transit.id}
                  access_credential={formatAccessCredential(transit)}
                  vehicle_state_and_plate={transit.formatted_plate}
                  closed_transit={
                    transit.entry_transit !== null &&
                    transit.exit_transit !== null
                  }
                  permit_busy_valid={transit.permit_busy_valid}
                  entered_before_cleared_inventory={transit.entered_before_cleared_inventory}
                  clickHandler={this.updateTransitView}
                />
              ))}
            </tbody>
          </table>
          {this.state.transits.length > 0 && (
            <button
              style={{ display: "block", margin: "0 auto" }}
              onClick={this.handleLoadMore}
              href="#"
              className="btn btn-default"
            >
              Load More
            </button>
          )}
        </div>
      </>
    );
  }
}

export default EntryExitTable;
