import React from "react";
import VticketButton from "./VticketButton";

const ParkedInventoryTableRow = ({
  dateTimeEnter,
  plateNumber,
  access_credential,
  permitState,
  permitId,
  transitType,
  transitId,
  garageId,
  clickHandler
}) => (
    <tr
      className="inventory-row"
      data-transit-id={transitId}
      onClick={() => clickHandler(garageId, transitId)}
    >
      <td>{dateTimeEnter}</td>
      <td>{plateNumber}</td>
      <td>{access_credential}</td>
      <td>{permitState}</td>
      <td>{permitId}</td>
      <td>{transitType === 'vTicket' ?
        <VticketButton garageId={garageId} transitId={transitId} /> :
        transitType}
      </td>
    </tr>
  );

export default ParkedInventoryTableRow;
