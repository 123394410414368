import React from "react";
import { sprintf } from "sprintf-js";
import { digestMessage } from "../Helper";

class TestAuthorizationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      garages: [],
      readers: [],
      credentialTypes: [
        { value: "rfid", name: "RFID" },
        { value: "card", name: "FOB" },
        { value: "phone", name: "Phone" },
        { value: "mobile", name: "Mobile" },
        { value: "barcode", name: "QR/Ticket" },
      ],
      credentialTypeSelected: "rfid",
      garageSelected: "",
      readerSelected: "",
      formTag: "",
      credential: "",
      loading: false,
      errorMessage: "",
      resultMessage: "",
      fetchedMobileDevices: [],
      response: null,
    };

    this.handleGarageSelectChange = this.handleGarageSelectChange.bind(this);
    this.handleReaderSelectChange = this.handleReaderSelectChange.bind(this);
    this.handleCredentialTypeChange = this.handleCredentialTypeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    fetch("/garages.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ ...this.state, garages: data });
      });
  }

  fetchReaders(garageId) {
    fetch(`/readers.json?garage=${garageId}`)
      .then((response) => response.json())
      .then((data) => this.setState({ ...this.state, readers: data }));
  }

  handleCredentialChange = (e) => this.setState({ ...this.state, credential: e.target.value });
  handleFormTagChange = (e) => this.setState({ ...this.state, formTag: e.target.value });
  handleCredentialTypeChange = (e) =>
    this.setState({ ...this.state, credentialTypeSelected: e.target.value });

  handleGarageSelectChange(e) {
    const garageSelected = this.state.garages.find((g) => g.id === parseInt(e.target.value)) || "";
    this.setState({ ...this.state, garageSelected });

    if (e.target.value !== "") {
      this.fetchReaders(e.target.value);
    } else {
      this.setState({ ...this.state, readers: [] });
    }
  }

  handleReaderSelectChange(e) {
    const readerSelected = this.state.readers.find((r) => r.id === parseInt(e.target.value)) || "";
    this.setState({ ...this.state, readerSelected });
  }

  fetchMobileAuthorization() {
    fetch("/api/v1/mobile_devices/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token token="${this.props.api_key}"`,
      },
      body: JSON.stringify({ search: { tag: this.state.formTag.trim() } }),
    })
      .then((response) => {
        if (![200, 422].includes(response.status)) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          console.log("Fetch mobile devices gave us: "+JSON.stringify(data));
          this.setState({ ...this.state, errorMessage: "", fetchedMobileDevices: data }, () => {
            this.fetchAuthorization();
          });
        } else {
          this.setState({
            ...this.state,
            errorMessage: "No mobile device connected to that Plate",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ ...this.state, loading: false, errorMessage: error });
      });
  }

  async buildParams() {
    let theParams = {
      detection: {
        test_auth: true,
        reader: {
          name: this.state.readerSelected.name,
          mac_address: this.state.readerSelected.mac_address,
        },
        tags: [
          {
            rfid: `C033 ${this.state.credential.trim()}`,
            rssi: 3000,
          },
        ],
      },
    };

    if (this.state.credentialTypeSelected === "mobile") {
      // here we need to generate the first 4 letters
      const addressHex = sprintf("%08X", this.state.fetchedMobileDevices[0].id);
      const newHashSecret = this.state.fetchedMobileDevices[0].new_hash_secret;
      const gateOpenCount = "00";
      var vehicle_index = -1;

      const mrvs = this.state.fetchedMobileDevices[0]["member_record"]["member_records_vehicles"];
      console.log("looping over mrvs: "+mrvs.count+" of them")
      for (var i = 0; i < mrvs.length; i++) {
        console.log("mrv: "+JSON.stringify(mrvs[i]));
        const mrv = mrvs[i];
        const vehicle = mrv["vehicle"];        
        if (vehicle.plate === this.state.formTag.trim()) {
          console.log("match")
          vehicle_index = mrv.vehicle_index;
          console.log("vehilce_index is now "+vehicle_index);
          break;
        }
        else {
          console.log("no match for "+vehicle.plate+" vs "+this.state.formTag.trim());
        }
      }
      
      const originalString = `${newHashSecret}${addressHex}${gateOpenCount}`;
      console.log("originalString: "+originalString);
      const encodedString = await digestMessage(originalString);

      theParams.detection.tags[0].vehicle_index = vehicle_index;
      theParams.detection.tags[0].type = "ble";
      theParams.detection.tags[0].rfid = sprintf("%08X", this.state.fetchedMobileDevices[0].id);
      theParams.detection.tags[0].utid = `${encodedString.substring(0, 4)} 00`;
    }

    if (this.state.credentialTypeSelected === "card") {
      theParams.detection.tags[0].type = "card";
      theParams.detection.tags[0].rfid = this.state.credential.trim();
    }

    if (this.state.credentialTypeSelected === "phone") {
      theParams.detection.tags[0].type = "phone";
      theParams.detection.tags[0].rfid = this.state.credential.trim();
    }
    if (this.state.credentialTypeSelected === "barcode") {
      theParams.detection.tags[0].type = "barcode";
      theParams.detection.tags[0].rfid = this.state.credential.trim();
    }

    return theParams;
  }

  async fetchAuthorization() {
    const params = await this.buildParams();

    this.setState({ ...this.state, loading: true, response: null }, () => {
      fetch("/detections", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token token="${this.props.api_key}"`,
        },
        body: JSON.stringify(params),
      })
        .then((response) => {
          if (![200, 422].includes(response.status)) {
            throw Error(response.statusText);
          }
          return response;
        })
        .then((response) => response.json())
        .then((data) => {
          // data may have an epoch time of the form:
          // payment due: 50.0, entered at: 1677455924.663075, code 13
          // We want to parse that
          var reason = data.reason;
          var augmented_reason = reason;
          var regex = /entered at: ([^,]+)/;
          var matches = (regex).exec(reason);
          if (matches && matches.length > 1) {
            console.log("date found.  will parse epoch time from "+matches[1]);
            const date_string = "entered at: "+ new Date(matches[1]*1000.0);
            augmented_reason = augmented_reason.replace(regex, date_string); 
          }
          else {
            console.log("entered at: not found in "+reason+" will not parse epoch time.");
          }
          var regex = /exited at: ([^,]+),/;
          var matches = (regex).exec(reason);
          if (matches && matches.length > 1) {
            console.log("date found.  will parse epoch time from "+matches[1]);
            const date_string = "exited at: "+ new Date(matches[1]*1000.0);
            augmented_reason = augmented_reason.replace(regex, date_string); 
          }
          else {
            console.log("exited at: not found in "+reason+" will not parse epoch time.");
          }
          data.reason = augmented_reason

          this.setState({ ...this.state, errorMessage: "", response: data, loading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ ...this.state, loading: false, errorMessage: error });
        });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    // Look up mobile device from email, new params
    if (this.state.credentialTypeSelected === "mobile") {
      this.fetchMobileAuthorization();
    } else {
      this.fetchAuthorization();
    }
  }

  render_response(response) {  
    console.log("response: "+JSON.stringify(response))
    var authorized = <h2 className="authorized">Authorized</h2>
    if (response.rfid == null) {
      authorized = <h2 className="not-authorized">Not Authorized</h2> 
    }
    if (response.permit_id != null) {
      authorized = <h2 className="authorized">Authorized by permit {response.permit_id}</h2> 
    }
    
    var permitHeader = ( <h3>No Permits</h3> ) 
    if (response.reasoning.permit_reasons.length > 0) {
      permitHeader = ( <h3>Evaluated Permits:</h3> ) 
    }
    var permitRows = [];
    for (var i = 0; i < response.reasoning.permit_reasons.length; i++) {
      var className = response.reasoning.permit_reasons[i].reason == "permit_active_and_unused" ? "authorized" : "not-authorized"
      permitRows.push ( <p key={i} className={className}>{response.reasoning.permit_reasons[i].permit_identifier}: {response.reasoning.permit_reasons[i].reason}</p> )
    }
    var hourlyInfo = null;
    var hourlyHeader = ( <p>No hourly information applicable</p> );
    if (response.reasoning.hourly_info) {
      hourlyHeader = ( <h3>Hourly Info:</h3> )
      hourlyInfo = (
        <div>
        <p className={response.reasoning.hourly_info.hourly_allowed ? "authorized" : "not-authorized"}>Hourly Enabled for Garage: {response.reasoning.hourly_info.hourly_allowed ? "YES" : "NO"}</p>
        <p className={response.reasoning.hourly_info.sufficient_funds ? "authorized" : "not-authorized"}>Sufficient Funds: {response.reasoning.hourly_info.sufficient_funds ? "YES" : "NO"}</p>
        <p className={response.reasoning.hourly_info.handsfree_autopay ? "authorized" : "not-authorized"}>Handsfree Autopay Enabled: {response.reasoning.hourly_info.handsfree_autopay ? "YES" : "NO"}</p>
        </div>
      )
    }

    return (
      <div>
        {authorized}
        <p>{response['reason']}</p>
        {permitHeader}
        {permitRows}
        {hourlyHeader}
        {hourlyInfo}
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.state.response && this.render_response(this.state.response)}
        {this.state.errorMessage && (
          <div className="alert alert-danger">{this.state.errorMessage}</div>
        )}
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <select
              onChange={this.handleGarageSelectChange}
              value={this.state.garageSelected.id || ""}
              className="form-control margin-fix"
              data-testid="garage-select"
            >
              <option value="" defaultValue>
                Select Garage
              </option>
              {this.state.garages.map((garage) => (
                <option key={garage.id} name="garage" value={garage.id}>
                  {garage.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <select
              onChange={this.handleReaderSelectChange}
              value={this.state.readerSelected.id || ""}
              className="form-control margin-fix"
            >
              <option value="" defaultValue>
                All Readers
              </option>
              {this.state.readers.map((reader) => (
                <option key={reader.id} name="reader" value={reader.id}>
                  {reader.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            {this.state.credentialTypes.map((credentialType) => {
              return (
                <label key={credentialType.value} className="radio-inline">
                  <input
                    onChange={this.handleCredentialTypeChange}
                    value={credentialType.value}
                    name="credential_type"
                    type="radio"
                    checked={this.state.credentialTypeSelected === credentialType.value}
                  />
                  {credentialType.name}
                </label>
              );
            })}
          </div>

          {this.state.credentialTypeSelected === "rfid" && (
            <div className="form-group">
              <input
                value={this.state.credential}
                onChange={this.handleCredentialChange}
                placeholder="C033 XXXX"
                type="text"
                name="credential"
                className="form-control"
              />
            </div>
          )}

          {this.state.credentialTypeSelected === "phone" && (
            <div className="form-group">
              <input
                value={this.state.credential}
                onChange={this.handleCredentialChange}
                placeholder="+1XXXYYYZZZZ"
                type="text"
                name="credential"
                className="form-control"
              />
            </div>
          )}

          {this.state.credentialTypeSelected === "barcode" && (
            <div className="form-group">
              <input
                value={this.state.credential}
                onChange={this.handleCredentialChange}
                placeholder="Enter full or human readable form (COMxx-xxxxxxxx or xx xxxx or S yyyy zzzz or V yyyy zzzz)"
                type="text"
                name="credential"
                className="form-control"
              />
            </div>
          )}

          {this.state.credentialTypeSelected === "card" && (
          
            <div className="form-group">
              <input
                value={this.state.formTag}
                onChange={this.handleFormTagChange}
                placeholder="card or fob id"
                type="text"
                name="credential"
                className="form-control"
              />
            </div>                   
          )}

          {this.state.credentialTypeSelected === "mobile" && (
            <div className="form-group">
              <p>Warning: When using this tool to check a mobile authorization, it will never allow a vehicle-specific permit.  These permits will always show "incorrect vehicle for permit code 09" until this tool is augment to support such permits on mobile</p>
              <input
                value={this.state.formTag}
                onChange={this.handleFormTagChange}
                placeholder="plate number"
                type="text"
                name="email"
                className="form-control"
              />
            </div>
          )}

          <button
            className="btn btn-primary"
            disabled={!(this.state.garageSelected && this.state.readerSelected)}
          >
            Tests Authorization
          </button>
        </form>
      </div>
    );
  }
}

export default TestAuthorizationForm;
