import React from "react";
import moment from "moment-timezone";
import DateTimePicker from "react-datetime-picker";

class EventLogSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      levels: ["Trace", "Debug", "Info", "Warn", "Error", "Critical"],
      garages: [],
      readers: [],
      targets: this.buildTargets(),
      targetSelected: this.getTarget(),
      levelSelected: 2,
      garageSelected: "",
      readerSelected: "",
      startDateTimeSelected: "",
      endDateTimeSelected: "",
      query: "",
      events: [],
      loading: false,
      errorMessage: "",
      searchTypes: ["Latest Logs", "Logs Before", "Log Range"],
      searchTypeSelected: "Latest Logs"
    };

    this.buildTimeStamp = this.buildTimeStamp.bind(this);
    this.handleGarageSelectChange = this.handleGarageSelectChange.bind(this);
    this.handleSearchTypeSelect = this.handleSearchTypeSelect.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showReaderName = this.showReaderName.bind(this);
  }

  componentDidMount() {
    console.log("url:"+process.env.query_microservice_url)
    fetch('/garages.json')
      .then(response => response.json())
      .then(data => {
        this.setState({...this.state, garages: data})
      });
  }

  getTarget() {
    const env = this.getEnvironment();
    if (env == "development") {
      return "staging";
    }
    else {
      return env;
    }
  }

  getEnvironment() {
    const hostname = location.hostname;

    if (hostname.includes("localhost")) {
      return "development";
    }
    else if (hostname.includes("staging") || hostname.includes("test")) {
      return "staging";
    }
    else {
      return "production";
    }
  }

  buildTargets() {
    if (this.getEnvironment() === "production") {
      return ["production", "warehouse"];
    }
    else if (this.getEnvironment() === "staging18") {
      return ["staging18", "warehouse"];
    }
    else {
      return ["staging", "warehouse"];
    }
  }

  buildStartTimeStamp() {
    const { startDateTimeSelected, garageSelected } = this.state;
    if (startDateTimeSelected !== "") {
      const date = moment.tz(startDateTimeSelected, garageSelected.time_zone)
      return date.toString();
    }  else {
      return "";
    }
  }

  buildEndTimeStamp() {
    const { endDateTimeSelected, garageSelected } = this.state;
    if (endDateTimeSelected !== "") {
      const date = moment.tz(endDateTimeSelected, garageSelected.time_zone)
      return date.toString();
    } else {
      return ""
    }
  }

  buildTimeStamp(serverTimeStamp) {
    return moment.tz(serverTimeStamp, this.state.garageSelected.time_zone).format("MM/DD HH:mm:ss SSS");
  }

  nowInGarageTimeZone() {
    return moment().tz(this.state.garageSelected.time_zone).toString();
  }

  buildParams() {
    return({
      garage_id: this.state.garageSelected.id,
      reader_id: this.state.readerSelected === "" ? "" : parseInt(this.state.readerSelected),
      after:     this.buildStartTimeStamp(),
      before:    this.state.searchTypeSelected === "Latest Logs" ? "" : this.buildEndTimeStamp(),
      limit: 100,
      query: this.state.query,
      min_level: parseInt(this.state.levelSelected),
      api_key: process.env.query_microservice_key,
      target: this.state.targetSelected,
      query_type: "event"
    })
  }

  buildLoadMoreParams() {
    let params = this.buildParams();
    const lastEvent = this.state.events[this.state.events.length - 1];
    params.before = moment.tz(lastEvent.logged_at, this.state.garageSelected.time_zone).toString();
    return params;
  }

  showReaderName(reader_id) {
    return this.state.readers.find(x => x.id === reader_id)?.name;
  }

  handleGarageSelectChange(e) {
    const garageSelected = this.state.garages.find((g) => g.id === parseInt(e.target.value)) || "";
    this.setState({...this.state, garageSelected})

    if (e.target.value !== "") {
      this.fetchReaders(e.target.value)
    } else {
      this.setState({...this.state, readers: []})
    }
  }

  handleReaderSelectChange = e => this.setState({...this.state, readerSelected: e.target.value});
  handleLevelSelectChange = e => this.setState({...this.state, levelSelected: e.target.value});
  handleStartDateTimeChange = dt => this.setState({...this.state, startDateTimeSelected: dt});
  handleEndDateTimeChange = dt => this.setState({...this.state, endDateTimeSelected: dt});
  handleQueryChange = e => this.setState({...this.state, query: e.target.value})
  handleTargetSelect = e => this.setState({...this.state, targetSelected: e.target.value})

  handleSearchTypeSelect(e) {
    let newDates;

    if (e.target.value === "Latest Logs") {
      newDates = {startDateSelected: "", startTimeSelected: "", endDateSelected: "", endTimeSelected: ""};
    } else if (e.target.value === "Logs Before") {
      newDates = {startDateSelected: "", startTimeSelected: ""};
    }

    const newParams = { searchTypeSelected: e.target.value, ...newDates}
    this.setState({...this.state, ...newParams})
  }
  closeDetail() {
    this.setState({...this.state, loading: false, errorMessage: null, detailRecord: null})
  }
  handleDetailClick(keyValue) {
    const entity = keyValue.split("=")[0].split("_id")[0];
    const id = keyValue.split("=")[1] ;
    const url = "/"+entity+"s/"+id;
    this.setState({...this.state, loading: true}, () => {
      fetch(url, {
        method: 'GET',
        headers:  { 'Accept': 'application/json' }  
      })
      .then(response => response.json())
      .then((data) => {
        this.setState({...this.state, detailRecord: data, loading: false})
      })
      .catch((error) => {
        console.error(error)
        this.setState({...this.state, loading: false, errorMessage: JSON.stringify(error)})
      })      
    })
  }
  handleSubmit(e) {
    e.preventDefault()
    const theParams = this.buildParams();
    console.log("SUBMIT PARAMS", theParams);

    this.setState({...this.state, loading: true}, () => {
      fetch(process.env.query_microservice_url, {
        method: 'POST',
        headers:  { 'Content-Type': 'application/json' },
        body: JSON.stringify(theParams)
      })
      .then((response) => {
        if (![200, 422].includes(response.status)) { throw Error(response.statusText); }
        return response;
      })
      .then(response => response.json())
      .then((data) => {
        if ('errorMessage' in data) {
          this.setState({...this.state, errorMessage: data.errorMessage, loading: false})
        } else {
          this.setState({...this.state, errorMessage: "", events: data, loading: false})
        }
      })
      .catch((error) => {
        console.error(error)
        this.setState({...this.state, loading: false, errorMessage: error})
      })
    })

  }

  handleLoadMore() {
    const loadMoreParams = this.buildLoadMoreParams()
    console.log("LOAD MORE PARAMS", loadMoreParams);

    this.setState({...this.state, loading: true}, () => {
      fetch(process.env.query_microservice_url, {
        method: 'POST',
        headers:  { 'Content-Type': 'application/json' },
        body: JSON.stringify(loadMoreParams)
      })
      .then(response => response.json())
      .then((data) => {
        this.setState({...this.state, events: this.state.events.concat(data), loading: false})
      })
    })
  }

  fetchReaders(garageId) {
    fetch(`/readers.json?garage=${garageId}`)
      .then(response => response.json())
      .then(data => this.setState({...this.state, readers: data}));
  }

  render() {
    const { 
      endDateTimeSelected, 
      errorMessage, 
      garageSelected, 
      garages, 
      levelSelected, 
      levels, 
      loading, 
      query, 
      readerSelected, 
      readers, 
      searchTypeSelected,
      searchTypes,
      startDateTimeSelected, 
      targetSelected, 
      targets,
      detailRecord
    }  = this.state;

    console.log("going to render");
    return(
      <div>
        {detailRecord &&
          <div className="modal fade in" style={{display: "block"}}>   
    
            <div className="modal-dialog" style={{width: "90%"}}>  
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.closeDetail.bind(this)}>&times;</button>
                  <h4 className="modal-title">Detail</h4>
                </div>
                <div className="modal-body">
                  <table style={{width: "100%"}}>                    
                  {Object.entries(detailRecord).map(([key, value], index) => {
                    return(
                      <tr key={key} style={{backgroundColor: index % 2 == 0 ? "#ffffff" : "#cccccc"}}>
                        <td>{key}</td>
                        <td style={{whiteSpace: "pre-wrap"}}>{value}</td>
                      </tr>
                    )
                    })
                  }
                  </table>
                </div>
              </div>
            </div>  
          </div>
    }
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <form className="form-inline event-log-search-form" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-4 data-source-radios">
              <label>Select Data Source:</label>
              {targets.map((target) => {
                return(
                  <label key={target} className="radio-inline">
                    <input onChange={this.handleTargetSelect} value={target} name="target" type="radio" checked={targetSelected === target} />
                    {target}
                  </label>
                )
              })}
            </div>
            <div className="col-sm-6 date-type-radios">
              <label>Select Search Type:</label>
              {searchTypes.map((searchType) => {
                return(
                  <label key={searchType} className="radio-inline">
                    <input onChange={this.handleSearchTypeSelect} value={searchType} name="search_type" type="radio" checked={searchTypeSelected === searchType} />
                    {searchType}
                  </label>
                )
              })}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <select onChange={this.handleGarageSelectChange} value={garageSelected.id || ""} className="form-control margin-fix" data-testid="garage-select">
                <option value="" defaultValue>Select Garage</option>
                {garages.map(garage => <option key={garage.id} name="garage" value={garage.id}>{`${garage.name} (${moment.tz(garage.time_zone).zoneAbbr('z')})`}</option>)}
              </select>
              <select onChange={this.handleReaderSelectChange} value={readerSelected} className="form-control margin-fix">
                <option value="" defaultValue>All Readers</option>
                {readers.map(reader => <option key={reader.id} name="reader" value={reader.id}>{reader.name}</option>)}
              </select>
              <select onChange={this.handleLevelSelectChange} value={levelSelected} className="form-control margin-fix">
                {levels.map((level, index) => <option key={level} name="reader" value={index}>{`${index} - ${level}`}</option>)}
              </select>
              {searchTypeSelected === "Log Range" &&
                <div className="date-time-select form-group" style={{ display: "inline-block" }}>
                  <label>Start Time</label><br />
                  <DateTimePicker 
                    clearIcon={null}
                    calendarIcon={null}
                    disableClock={true} 
                    value={startDateTimeSelected} 
                    format="M/d/y HH:mm" 
                    onChange={this.handleStartDateTimeChange}/>
                </div>
              }
              {searchTypeSelected !== "Latest Logs" &&
                <div className="date-time-select form-group" style={{ display: "inline-block" }}>
                  <label>End Time</label><br />
                  <DateTimePicker 
                    clearIcon={null}
                    calendarIcon={null}
                    disableClock={true} 
                    value={endDateTimeSelected} 
                    format="M/d/y HH:mm" 
                    onChange={this.handleEndDateTimeChange}/>
                </div>
              }
              <div className="form-group" style={{ display: "inline-block", position: "relative" }}>
                <input value={query} onChange={this.handleQueryChange} className="form-control margin-fix" placeholder="Search by event text" type="text" />
                <br />
                { query && targetSelected !== "warehouse" && <small style={{ position: "absolute", top: "55px" }} className="text-danger">Are you sure?</small> }
              </div>
              <button type="submit" className="btn btn-primary margin-fix" disabled={(garageSelected === "" || loading) && !errorMessage}>
                { loading ?
                  <span>Searching...</span> :
                  <span>Search</span>
                }
              </button>
            </div>
          </div>
        </form>

        <table className="table">
          <thead>
            <tr>
              <th style={{width: "150px"}}>Time</th>
              <th style={{width: "150px", textAlign: "center"}}>Reader</th>
              <th style={{textAlign: "center"}}>Level</th>
              <th>Event</th>
            </tr>
          </thead>
          <tbody>
            {this.state.events.map((event, index) => {   

              console.log("event.event: "+event.event);
              // parse out {{transcript=1234}} from event string
              var link_open = "";
              var link_close = "";
              var event_html = event.event;
              if (event.event.indexOf("{{") != -1) {
                const parts = event.event.split("{{")[1].split("}}")[0];
                const strippedEvent = event.event.split("{{")[0]+event.event.split("}}")[1];
                event_html = (
                  <a onClick={this.handleDetailClick.bind(this, parts)}>
                  {strippedEvent}
                  </a>
                )
              }

              return(
                <tr key={`${event.logged_at}${index}`}>
                  <td>{this.buildTimeStamp(event.logged_at)}</td>
                  <td style={{textAlign: "center"}}>{this.showReaderName(event.reader_id)}</td>
                  <td style={{textAlign: "center"}}>{event.level}</td>
                  <td>{event_html}</td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <div className="row">
          <div style={{textAlign: "center"}} className="col-sm-12">
            {this.state.events.length > 0 &&
              <button onClick={this.handleLoadMore} className="btn btn-default" disabled={loading}>
                { loading ?
                  <span>Loading...</span> :
                  <span>Load More</span>
                }
              </button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default EventLogSearch;
