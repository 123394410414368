import React from "react"
import PropTypes from "prop-types"
class GarageOps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      garageId: window.garageOpsGarageId,
      isLoading: false,
      garageGateState: null      
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.fetchGarageGateState = this.fetchGarageGateState.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log("Field changed")
    console.log("name: "+name);
    console.log("value: "+value);
    //[Log] name: reader_0_gate_mode (garage_ops-cd87786d06db53b53235.js, line 154)
    //[Log] value: hold_open (garage_ops-cd87786d06db53b53235.js, line 155)
    var controller_index = Number(name.split("_")[1]);
    console.log("changing controller index "+controller_index);
    var garageGateState = this.state.garageGateState;
    garageGateState["controllers"][controller_index]["gate_mode"] = value;
    this.setState({garageGateState: garageGateState});
    console.log(JSON.stringify(garageGateState));
  }


  render () {
      const message = this.state.errorMessage || ""
      
      var controllers = []
      if (this.state.garageGateState && this.state.garageGateState["controllers"]) {
        controllers = this.state.garageGateState["controllers"];
      }
      
      return (
        <React.Fragment>
        <div id="ops-inner" className={this.state.isLoading ? "loading" : "done-loading"}>
          <p>{message}</p>          
          <form>
          {controllers.map((controller, _index) => (
            <div key={_index}>
                        <p>{controller.name}</p>
<input type="radio" id={"reader_"+_index+"_gate_mode_normal"} name={"reader_"+_index+"_gate_mode"} value="" defaultChecked={controllers[_index].gate_mode == ""} key={_index+".1"} onChange={this.handleInputChange}/>
<span>Normal</span>
<input type="radio" id={"reader_"+_index+"_gate_mode_auto_open"} name={"reader_"+_index+"_gate_mode"} value="auto_open" defaultChecked={controllers[_index].gate_mode == "auto_open"} key={_index+".2"} onChange={this.handleInputChange}/>
<span>Auto-Open</span>
<input type="radio" id={"reader_"+_index+"_gate_mode_hold_open"} name={"reader_"+_index+"_gate_mode"} value="hold_open" defaultChecked={controllers[_index].gate_mode == "hold_open"} key={_index+".3"} onChange={this.handleInputChange}/>
<span>Hold-Open</span>
            </div>
          ))}
<input type="button" value="Save" onClick={this.handleSave} />
<input type="button" value="Cancel" onClick={this.closeDialog} />
          </form>
        </div>
        </React.Fragment>

      )
  }

  componentDidMount() {
     this.fetchGarageGateState();
  }
  
  closeDialog() {
    $("#modal-garage-ops").modal("hide");
  }
  
  async handleSave() {
    var url = "/api/v2/garage_gate_state/"+this.state.garageId
    this.setState({...this.state, isLoading: true}, () => {      
      fetch(url, {
          method: "PUT",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.state.garageGateState)
        })
        .then((response) => {
          if (![200, 422].includes(response.status)) { throw Error(response.statusText); }
          $("#modal-garage-ops").modal("hide");
          window.location = window.location; // force reload of whole window
          return response;
        })
        .then(response => response.json())
        .then(data=> {
          if ('errorMessage' in data) {
            // Handle error
            this.setState({errorMessage: data.errorMessage, isLoading: false})
          } else {
            this.setState({errorMessage: "", isLoading: false})
          }
        })
        .catch((error) => {
          console.error(error)
          this.setState({...this.state, errorMessage: ""+error, isLoading: false})
        })
        .finally(() => {
          this.setState({...this.state, isLoading: false})
        })
    })
  }

  fetchGarageGateState() {
    var that = this;
    var url = "/api/v2/garage_gate_state/"+that.state.garageId

    var responseAfterPromise = null;
    that.setState({isLoading: false, errorMessage: null});

    console.log("url: "+url);

    fetch(url).then(response => {
          if (response.status == 200) {
            response.json().then(json => {
              if (json.errors && typeof json.errors === 'object' && json.errors.constructor === Array && json.errors.length > 0) {
                errorMessage = json.errors.join("; ");
                that.setState({report: null, isLoading: false, errorMessage: errorMessage});
                return Promise.reject(new Error(errorMessage))
              }
              else {
                that.setState({garageGateState: json, isLoading: false, errorMessage: null});
                return Promise.resolve(json)
              }
            })
            .catch(function(error) {
	          that.setState({isLoading: false});
	          var errorString = ""+error;
               that.setState({report: null, isLoading: false, errorMessage: ''+error});
            });
          }
          else {
            errorMessage = "System error: "+response.statusText;
            that.setState({report: null, isLoading: false, errorMessage: errorMessage});
          }
      });
  }
}

GarageOps.propTypes = {
  greeting: PropTypes.string
};
export default GarageOps