import React from "react"
import PropTypes from "prop-types"
class NestOverstays extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: null,
      isLoading: false,
      errorMessage: null,
      key: "nest_overstay-"+this.reformatDate(this.formatDate(new Date(new Date().getTime()-3600*24*1000))),
      //nest_garage_id: props.nest_garage_id,
      //parent_garage_id: props.parent_garage_id,
      web_aws_access_key_id: props.web_aws_access_key_id,
      web_aws_secret_access_key: props.web_aws_secret_access_key,
      aws_env_name: props.aws_env_name,
    };
    console.log("Starting with key "+this.state.key);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log("name: "+name);
    console.log("value: "+value);
    const newKey = "nest_overstay-"+this.reformatDate(value);
    this.setState({key: newKey});
    this.fetchNestOverstays(newKey);
  }

  padTime(s) {
    var s = Math.floor(s);
    if (s >= 10) {
      return ""+s;
    }
    return "0"+s;
  }

  formatDuration(d) {
    return ""+this.padTime(d/3600)+":"+this.padTime((d % 3600) / 60) + ":"+this.padTime(d % 60);
  }
  formatTime(str) {
    if (str == null) {
      return "";
    }
    var date = new Date(str);
    var day = date.getDate();
    var month = date.getMonth()+1;
    return ""+month+"/"+day+" "+this.padTime(date.getHours())+":"+this.padTime(date.getMinutes())+":"+this.padTime(date.getSeconds());
  }


  reformatDate(dateString) {
     return dateString.replace("/", "-").replace("/", "-")
  }

  formatDate(date) {
    var year = date.getYear()+1900;
    var day = date.getDate();
    var month = date.getMonth()+1;
    return year+"/"+month+"/"+day;
  }


  render () {
    var header = "No nest member transits";
    if (this.state.errorMessage) {
      header = this.state.errorMessage;
    }
    var options = [];
    for (var days_ago = 1; days_ago < 7; days_ago++) {
      const formattedDate = this.formatDate(new Date(new Date().getTime()-3600*24*1000*days_ago));
      var selected = false;
      if (this.state.key.indexOf(this.reformatDate(formattedDate)) >= 0) {
        selected = true;
      }
      options.push(
        <option selected={selected}>{formattedDate}</option>
      )
    }
    const report_nav = (
        <p>Show report for: <select onChange={this.handleInputChange}>
          <option></option>
          {options}
        </select></p>
    )


    if (this.state.report == null) {
      const message = this.state.errorMessage || "Report not available"
      return (
        <React.Fragment>
        <div id="nest_overstays" className={this.state.isLoading ? "loading" : "done-loading"}>
          <p>{message}.</p>
          {report_nav}
        </div>
        </React.Fragment>

      )
    }

    var main_table = "";
    if (this.state.report.records.length > 0) {
      header = ""+this.state.report.records.length+" nest member transits";

      var html_rows = [];
      for (var row = 0; row < this.state.report.records.length; row++) {
        var record = this.state.report.records[row];
        var html_row = (
            <tr >
            <td>{this.formatTime(record.parent_entered_at)}</td>
            <td>{this.formatTime(record.nest_entered_at)}</td>
            <td>{this.formatTime(record.parent_exited_at)}</td>
            <td className={record.hourly_duration > 15*60 ? "overstay" : "normal"}>{record.nest_entered_at ? this.formatDuration(record.hourly_duration) : ""}</td>
            <td>{record.nest_entered_at ? "" : this.formatDuration(record.hourly_duration)}</td>
            <td>{record.credential}</td>
            <td>{record.firstname} {record.lastname}</td>
            </tr>
        );
        html_rows.push(html_row)
      }
      main_table = (
        <table className="main_table">
        <tbody>
            <tr>
            <th>Date/Time In</th>
            <th>Date/Time Parked (Nest)</th>
            <th>Date/Time Out</th>
            <th>Time In to Nest (Duration)</th>
            <th>Time Of Retail Stay (Duration)</th>
            <th>Tag ID</th>
            <th>Name</th>
            </tr>
        {html_rows}
        </tbody>
        </table>
      );
    }
      return (
        <React.Fragment>
        <div id="nest_overstays" className={this.state.isLoading ? "loading" : "done-loading"}>
        <h1>{this.state.report.nest_garage.name} Nest Transit Report</h1>
        <div id="header">
        {report_nav}
        <p>Starting: {this.formatTime(this.state.report.start_time)}</p>
        <p>Ending: {this.formatTime(this.state.report.end_time)}</p>
        <p>{header}</p>
        </div>
        {main_table}
        </div>
        </React.Fragment>
      )

  }

  componentDidMount() {
    this.fetchNestOverstays();
  }

  fetchNestOverstays(newKey) {
    var that = this;
    var url = this.props.baseUrl+"/api/v2/transits_reports/nest_overstay"

    const key = newKey || this.state.key;
    if (key) {
      url += "?key="+key;
    }
    else {
      url += "?nest_garage_id="+this.state.nest_garage_id+"&parent_garage_id="+this.state.parent_garage_id;
    }
    var responseAfterPromise = null;
    that.setState({isLoading: false, errorMessage: null});

    console.log("url: "+url);

    fetch(url).then(response => {
          if (response.status == 200) {
            response.json().then(json => {
              if (json.errors && typeof json.errors === 'object' && json.errors.constructor === Array && json.errors.length > 0) {
                errorMessage = json.errors.join("; ");
                that.setState({report: null, isLoading: false, errorMessage: errorMessage});
                return Promise.reject(new Error(errorMessage))
              }
              else {
                that.setState({report: json, isLoading: false, errorMessage: null});
                return Promise.resolve(json)
              }
            })
            .catch(function(error) {
	          that.setState({isLoading: false});
	          var errorString = ""+error;
               that.setState({report: null, isLoading: false, errorMessage: ''+error});
            });
          }
          else {
            var errorMessage = response.statusText;
            if (response.status == 404) {
              errorMessage = "Report not available";
            }
            that.setState({report: null, isLoading: false, errorMessage: errorMessage});
          }
      });
  }
}

NestOverstays.propTypes = {
  greeting: PropTypes.string
};
export default NestOverstays
