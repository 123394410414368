import React from "react";
import ParkedInventoryTableRow from "./ParkedInventoryTableRow";
import { formatAccessCredential } from "../Helper";
import _ from "lodash";
import classNames from "classnames";

class ParkedInventoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOptions: {
        time: { dir: "desc", attr: "entry_detected_at", sorting: true },
        plate: { dir: "desc", attr: "formatted_plate", sorting: false },
        rfid: { dir: "desc", attr: "formatted_rfid", sorting: false },
        type: { dir: "desc", attr: "formatted_status", sorting: false },
      },
      transits: [],
      loading: false,
      loaded: false,
    };

    this.handleSort = this.handleSort.bind(this);
    this.rowClickHandler = this.rowClickHandler.bind(this);
    this.addTransit = this.addTransit.bind(this);
    this.handleExitTransit = this.handleExitTransit.bind(this);
    this.handleNewTransits = this.handleNewTransits.bind(this);
    this.fetchParkedInventory = this.fetchParkedInventory.bind(this);
  }

  handleNewTransits(transits) {
    if (this.state.loaded) {
      transits.forEach((transit) => {
        if (
          transit.transit_type === "entry" &&
          (transit.tag || transit.mobile_device || transit.status === "vTicket")
        ) {
          this.addTransit(transit);
        } else if (transit.transit_type === "exit") {
          this.handleExitTransit(transit);
        }
      });
    }
  }

  addTransit(transit) {
    const { transits, sortOptions } = this.state;
    const currentOption = _.find(sortOptions, (option) => option.sorting === true);
    const sortedTransits = _.orderBy([transit, ...transits], currentOption.attr, currentOption.dir);
    this.setState({ ...this.state, transits: sortedTransits });
  }

  handleExitTransit(exitTransit) {
    const { transits } = this.state;

    if (exitTransit.entry_transit) {
      const filteredTransits = _.reject(
        transits,
        (transit, _i, _t) => transit.id === exitTransit.entry_transit.id
      );
      this.setState({ ...this.state, transits: filteredTransits });
    }
  }

  fetchParkedInventory() {
    this.setState({ ...this.state, loading: true });
    const url = window.location.pathname;
    let queryString = "";
    queryString += url.substring(url.lastIndexOf("/") + 1);

    if (this.state.transits.length > 0) {
      queryString += `?after=${this.state.transits[this.state.transits.length - 1].id}`;
    }

    fetch("/api/v2/parked_inventory/" + queryString)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            ...this.state,
            loading: false,
            loaded: true,
            transits: [...this.state.transits, ...result],
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  handleSort(col) {
    const { transits, sortOptions } = this.state;
    const newDirection = sortOptions[col].dir === "asc" ? "desc" : "asc";

    const sortedTransits = _.orderBy(transits, sortOptions[col].attr, newDirection);

    sortOptions.rfid.sorting = false;
    sortOptions.plate.sorting = false;
    sortOptions.time.sorting = false;
    sortOptions[col].dir = newDirection;
    sortOptions[col].sorting = true;

    this.setState({ ...this.state, sortOptions, transits: sortedTransits });
  }

  rowClickHandler(garageId, transitId) {
    // global from work_area.js - refactor once entry/exit is brought over
    getTransit(garageId, transitId);
  }

  buttonIconClasses(sortOption) {
    return classNames(
      "glyphicon",
      { "glyphicon-sort-by-attributes": sortOption.dir === "asc" },
      { "glyphicon-sort-by-attributes-alt": sortOption.dir === "desc" },
      { active: sortOption.sorting }
    );
  }

  render() {
    const { sortOptions, loading, transits } = this.state;
    return (
      <div>
        <table id="parked-inventory-table" className="table table-hover">
          <thead>
            <tr>
              <th>
                Date/Time Enter
                <button
                  className="btn btn-default btn-sm"
                  onClick={() => {
                    this.handleSort("time");
                  }}
                >
                  <span className={this.buttonIconClasses(sortOptions.time)}></span>
                </button>
              </th>
              <th>
                Plate Number
                <button
                  className="btn btn-default btn-sm"
                  onClick={() => {
                    this.handleSort("plate");
                  }}
                >
                  <span className={this.buttonIconClasses(sortOptions.plate)}></span>
                </button>
              </th>
              <th>
                Access
                <button
                  className="btn btn-default btn-sm"
                  onClick={() => {
                    this.handleSort("rfid");
                  }}
                >
                  <span className={this.buttonIconClasses(sortOptions.rfid)}></span>
                </button>
              </th>
              <th>Permit State</th>
              <th>Permit ID</th>
              <th>
                Transit Type
                <button
                  className="btn btn-default btn-sm"
                  onClick={() => {
                    this.handleSort("type");
                  }}
                >
                  <span className={this.buttonIconClasses(sortOptions.type)}></span>
                </button>
                </th>
            </tr>
          </thead>
          <tbody>
            {loading && transits.length === 0 && (
              <div style={{ position: "absolute", top: "100px", left: "50%" }}>Loading</div>
            )}
            {transits.map((transit, _index) => (
              <ParkedInventoryTableRow
                key={transit.id}
                transitId={transit.id}
                garageId={transit.garage_id}
                dateTimeEnter={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: transit.formatted_entry_time,
                    }}
                  />
                }
                plateNumber={transit.formatted_plate}
                access_credential={formatAccessCredential(transit)}
                permitState={transit.formatted_permit_state}
                permitId={transit.formatted_permit_id}
                transitType={transit.formatted_status}
                clickHandler={this.rowClickHandler}
              />
            ))}
          </tbody>
        </table>
        {transits.length > 0 && (
          <button
            style={{ display: "block", margin: "0 auto" }}
            onClick={this.fetchParkedInventory}
            href="#"
            className="btn btn-default"
          >
            Load More
          </button>
        )}
      </div>
    );
  }
}

export default ParkedInventoryTable;
