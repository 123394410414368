import React from "react";

const VticketButton = ({ transitId, garageId }) => (
  <a
    className="btn btn-default"
    id="create-exit-vticket"
    data-remote="true"
    href={`/garages/${garageId}/open_gates/new?ticketless=true&corresponding_entry_transit_id=${transitId}`}
    style={{ display: "block" }}>
    Exit vTicket
  </a>

);

export default VticketButton;

