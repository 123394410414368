import React from "react"
import PropTypes from "prop-types"
class TagReads extends React.Component {
  constructor(props) {
    super(props);
    this._last_sqs_message = null;
    this.state = {
      tag_reads: [],
      isLoading: false,
      errorMessage: null,
      web_aws_access_key_id: props.web_aws_access_key_id,
      web_aws_secret_access_key: props.web_aws_secret_access_key,
      aws_env_name: props.aws_env_name,
    };
  }

  render () {
    var header = "No Tag Reads Available";
    if (this.state.errorMessage) {
      header = this.state.errorMessage;
    }
    if (this.state.tag_reads.length > 0) {
      header = Date(Date.parse(this.state.tag_reads[0]["created_at"]));
    }
    var rows = []
    var other_tags = []

    let max_row = 6;
    var max_populated_row = 0;
    for (var row = 0; row < max_row; row++) {
      rows[row] = [null, null];
    }
    for (var i = 0; i < this.state.tag_reads.length; i++) {
      var tag = this.state.tag_reads[i];
      //console.log("tag: "+JSON.stringify(tag));
      var numeric_id = tag.identifier.substring(10)*1;
      var row = Math.floor((numeric_id-1)/2);
      var col = (numeric_id-1)-row*2;
      //console.log("row,col: "+row+","+col);
      if (row < max_row) {
        if (row > max_populated_row) {
          max_populated_row = row;
        }
        rows[row][col] = tag;
      }
      else {
        other_tags.push(tag);
      }
    }

    var main_table_rows = [];
    for (var row = 0; row <= max_populated_row; row++) {
      var cols = [];
      for (var col = 0; col <= 1; col++) {
        var tag = rows[row][col];
        var cell;
        if (tag == null) {
          cell = (<td className="tag_none"></td>);
        }
        else {
          var klass = "tag_good";
          if (tag.count < 10) {
            klass = "tag_bad";
          }
          cell = (
            <td className={klass}>
            <p className="tag_id">{tag.identifier}</p>
            <p className="count">{tag.count}</p>
            <p className="rssi">RSSI: {tag.rssi}, Antenna: {tag.antenna ? tag.antenna : "unknown"}</p>
            </td>
            );
        }
        cols[col] = cell;
      }
      main_table_rows.push(<tr key={row}>{cols[0]}{cols[1]}</tr>)
    }
    var extra_table_rows = [];
    for (var row = 0; row < other_tags.length; row++) {
      var tag = other_tags[row];
      var klass = "tag_good";
      if (tag.count < 10) {
        klass = "tag_bad";
      }
      cell = (
            <td className={klass}>
            <p className="tag_id">{tag.identifier}</p>
            <p className="count">{tag.count}</p>
            <p className="rssi">RSSI: {tag.rssi}, Antenna: {tag.antenna ? tag.antenna : "unknown"}</p>
            </td>
      );
      extra_table_rows.push(<tr key={row}>{cell}</tr>)
    }


    if (this.state.tag_reads.length > 0) {
    return (
        <React.Fragment>
        <div id="tag_reads" className={this.state.isLoading ? "loading" : "done-loading"}>
        {header}
        <table className="main_table">
        <tbody>
        {main_table_rows}
        </tbody>
        </table>
        <p>Additional tags</p>
        <table>
        <tbody>
        {extra_table_rows}
        </tbody>
        </table>
        </div>
        </React.Fragment>
      );
    }
    else {
    return (
        <React.Fragment>
        <div id="tag_reads" className={this.state.isLoading ? "loading" : "done-loading"}>
        {header}
        </div>
        </React.Fragment>
      );
    }
  }

  componentDidMount() {
    this.fetchTagReads();
    this.watchForNextTagRead();
  }

  fetchTagReads() {
    var that = this;
    var url = this.props.baseUrl+'/api/v2/tag_reads';
    var responseAfterPromise = null;
    //console.log('start fetchTagReads '+url);
    that.setState({isLoading: false, errorMessage: null});
    fetch(url)
      .then(res => {responseAfterPromise = res; return res.json();})
	    .then(json => {
          //console.log('end fetchTagReads: '+json);
          if (responseAfterPromise.status === 200) {
            that.setState({tag_reads: json, isLoading: false, errorMessage: null});
            return Promise.resolve(json)
          }
          var errorMessage = responseAfterPromise.statusText;
          if (json.errors && typeof json.errors === 'object' && json.errors.constructor === Array && json.errors.length > 0) {
            errorMessage = json.errors.join("; ");
          }
          return Promise.reject(new Error(errorMessage))
	    })
	    .catch(function(error) {
	      that.setState({isLoading: false});
	      var errorString = ""+error;
          that.setState({isLoading: false, errorMessage: ''+error});
        });
  }

  watchForNextTagRead() {
    console.log("starting sqs watch");
    var sqs = new AWS.SQS({
      apiVersion: '2012-11-05',
      accessKeyId: this.state.web_aws_access_key_id,
      secretAccessKey: this.state.web_aws_secret_access_key,
      region: 'us-east-1'
     });
    var params = {
      QueueName: aws_env_name+"_tag_reads_"+dashboard_session_id
    };
    var that = this;
    console.log("Getting queue for "+JSON.stringify(params));
    sqs.getQueueUrl(params, function(err, data) {
       if (err) {
         console.log("cannot get SQS queue URL for "+params+" due to error: "+err);
       }
       else {
         var params  =  {'QueueUrl': data['QueueUrl'], 'WaitTimeSeconds': 1};
         sqs.receiveMessage(params, function(err, data) {
           if (err) {
             console.log("Error polling SQS: "+err);
             console.log("SQS Queue name: "+params.QueueName);
           }
           else {
             console.log("Message count: "+ data['Messages'].length);
             if (data['Messages'].length > 0) {
               //console.log("1st message: "+JSON.stringify(data['Messages'][0]));
               //console.log("1st message body: "+data['Messages'][0]['Body']);
               var sqsMessage = data['Messages'][0]['Body'];
                console.log("SQS message changed from "+that._last_sqs_message+" to "+sqsMessage);
                that._last_sqs_message = sqsMessage;
                that.fetchTagReads();
             }
             that.watchForNextTagRead();
           }
         });
       }
    });
  }

}

TagReads.propTypes = {
  greeting: PropTypes.string
};
export default TagReads
