import React from "react"
import PropTypes from "prop-types"

class ControllerTunnelStatus extends React.Component {
  constructor(props) {
    super(props);
    this.refreshTimerStarted = false
    this.state = {
      controller_tunnels: [],
      isLoading: false,
      errorMessage: null,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  
  async handleOpen(controller_tunnel) {
    console.log("handle open called for controller name "+controller_tunnel.name)
    var that = this;
    that.setState({isLoading: true, errorMessage: null, nameRequestedOpen: controller_tunnel.name});
    var url = this.props.baseUrl+'/api/v2/controller_tunnels';
    var responseAfterPromise = null;
    fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({name: controller_tunnel.name, destination: "pi@108.51.101.192"})
      })
      .then(res => {responseAfterPromise = res; return res.json();})
	    .then(json => {
          if (responseAfterPromise.status === 200) {
            that.setState({controller_tunnels: json, isLoading: false, errorMessage: null});
            that.scheduleRefreshIfNeeded()
            return Promise.resolve(json)
          }
          var errorMessage = responseAfterPromise.statusText;
          if (json.error) {
            errorMessage = json.error;
          }
          return Promise.reject(new Error(errorMessage))
	    })
	    .catch(function(error) {
	      that.setState({isLoading: false});
	      var errorString = ""+error;
        that.setState({isLoading: false, errorMessage: ''+error});
      });    
  }
  async handleCancel(controller_tunnel) {
    var that = this;
    that.setState({isLoading: true, errorMessage: null, nameRequestedOpen: null});
    var url = this.props.baseUrl+'/api/v2/controller_tunnels/'+controller_tunnel.name;
    var responseAfterPromise = null;
    fetch(url, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({name: controller_tunnel.name})
      })
      .then(res => {responseAfterPromise = res; return res.json();})
	    .then(json => {
          if (responseAfterPromise.status === 200) {
            that.setState({controller_tunnels: json, isLoading: false, errorMessage: null});
     	      console.log("got json"+json);
            return Promise.resolve(json)
          }
          var errorMessage = responseAfterPromise.statusText;
          if (json.error) {
            errorMessage = json.error;
          }
     	    console.log("got error 1 "+errorMessage);
          return Promise.reject(new Error(errorMessage))
	    })
	    .catch(function(error) {
	      var errorString = ""+error;
	      console.log("got error "+error);
        that.setState({isLoading: false, errorMessage: ''+errorString});
        console.log("Set the state with the error")
      });    
  }
  
  scheduleRefreshIfNeeded() {
    for (var i = 0; i < this.state.controller_tunnels.length; i++) {
      var controller_tunnel = this.state.controller_tunnels[i];
      var requested = controller_tunnel.requested
      var name = controller_tunnel.name || "unknown controller"
      var status = controller_tunnel.status || "unknown status"
      var last_commanded_at = new Date(0)
      if (controller_tunnel.last_commanded_at != null) {
        last_commanded_at = new Date(Date.parse(controller_tunnel.last_commanded_at))
      }
      var millis_since_commanded = (new Date().getTime() - last_commanded_at.getTime())
      if (millis_since_commanded < 90000 && requested != "" && status != "connected") {        
        if (!this.refreshTimerStarted) {
          this.refreshTimerStarted = true
          console.log("Command happend only "+millis_since_commanded+" millis ago.  We will refresh in 10 seconds...")        
          setTimeout(function() {         
            this.refreshTimerStarted = false
            console.log("We are refreshing now")
            this.fetchControllerTunnels(name)          
          }.bind(this), 10000)
        }
        else {
          console.log("Refresh timer already started")
        }
      }
    }
  }

  render () {
    var header = "Controller Tunnel Status Loading...";
    if (this.state.errorMessage) {
      header = "Error Loding Data: "+this.state.errorMessage;
    }
    else if (this.state.controller_tunnels.length > 0) {
      header = "Controllers Configured for Tunnel Operation ("+ this.state.controller_tunnels.length+"):"
    }
    var connecting = false

    var main_table_rows = [];
    for (var i = 0; i < this.state.controller_tunnels.length; i++) {
      var controller_tunnel = this.state.controller_tunnels[i];
      var name = controller_tunnel.name || "unknown controller"
      var status = controller_tunnel.status || "unknown status"
      var last_reported_at = controller_tunnel.last_reported_at || "Never reported"
      var last_commanded_at = new Date(0)
      if (controller_tunnel.last_commanded_at != null) {
        last_commanded_at = new Date(Date.parse(controller_tunnel.last_commanded_at))
      }
      console.log("Last commanded at is "+last_commanded_at)
      
      var millis_since_commanded = (new Date().getTime() - last_commanded_at.getTime())
      var requested = controller_tunnel.requested
      
      var button = (
        <input type="button" onClick={this.handleOpen.bind(this,controller_tunnel)} type="submit" disabled={this.state.isLoading} value="Open"/>      
      )
      if (requested) {
        button = (
          <input type="button" onClick={this.handleCancel.bind(this,controller_tunnel)} type="submit" disabled={this.state.isLoading} value="Cancel"/>      
        )
      }
      var waiting = false
      if (millis_since_commanded < 90000 && requested != "" && status != "connected") {        
        waiting = true
        button = ( <img className="spinner" src="/assets/spinner.gif"/> )
        status = ( <img className="spinner" src="/assets/spinner.gif"/> )
        waiting = true
      }
      else if (requested != "" && status != "connected") {
        // we have timed out
        if (this.state.nameRequestedOpen == name) {
          console.log("send timeout alert at "+ new Date())
          alert("Timeout waiting for tunnel to open")
        }
      }
      else if (requested != "" && status == "connected") {
        if (this.state.nameRequestedOpen == name) {
          console.log("send open success alert at "+ new Date())
          alert("Tunnel opened successfully")
        }
      }
      var row = (
            <tr key={name}>
            <td>{name}</td>
            <td>{status}</td>
            <td>{last_reported_at}</td>
            <td>{requested}</td>
            <td>{button}</td>
            </tr>
            );
      main_table_rows.push(row)
   }

    var wait_instructions = ""
    if (waiting) {
      wait_instructions = "Controller tunnels normally take up to 90 seconds to establish.  Please wait."
    }
    if (this.state.controller_tunnels.length > 0) {
    return (
        <React.Fragment>
        <div id="controller_tunnel" className={this.state.isLoading ? "loading" : "done-loading"}>
        {header}
        <table className="main_table">
        <thead>
        <tr>
        <th>Controller</th>
        <th>Last Status</th>
        <th>Last Reported</th>
        <th>Tunnel Request</th>
        <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {main_table_rows}
        </tbody>
        </table>
        </div>
        <p>Instructions for using the tunnel:  For any controller with a status of "connected", you may ssh in to the server show in the "tunnel request" column, then initiate another ssh into the port shown (e.g. ssh localhost -p 2222).  This will allow you to access the controller.</p>
        <p>{wait_instructions}</p>
        </React.Fragment>
      );
    }
    else {
    return (
        <React.Fragment>
        <div id="tag_reads" className={this.state.isLoading ? "loading" : "done-loading"}>
        {header}
        </div>
        </React.Fragment>
      );
    }
  }

  componentDidMount() {
    this.fetchControllerTunnels();
  }

  fetchControllerTunnels(nameRequestedOpen) {
    
    var that = this;
    var url = this.props.baseUrl+'/api/v2/controller_tunnels';
    var responseAfterPromise = null;
    that.setState({isLoading: true, errorMessage: null});
    fetch(url)
      .then(res => {responseAfterPromise = res.clone(); console.log("fetch tunnels 1.5: "); return res.json();})
	    .then(json => {
          if (responseAfterPromise.status === 200) {
            that.setState({controller_tunnels: json, isLoading: false, errorMessage: null, nameRequestedOpen: nameRequestedOpen});
            that.scheduleRefreshIfNeeded()
            return Promise.resolve(json)
          }
          var errorMessage = responseAfterPromise.statusText;
          if (json.error) {
            errorMessage = json.error;
          }
          return Promise.reject(new Error(errorMessage))
	    })
	    .catch(function(error) {
	      responseAfterPromise.text().then((txt) => console.log(`Status code: ${responseAfterPromise.status} status message: ${responseAfterPromise.statusText} Body: ${txt}`));
	      var errorString = ""+error;
	      console.log(error)	      
        that.setState({controller_tunnels: [], isLoading: false, errorMessage: ''+error});
      });
  }
}

ControllerTunnelStatus.propTypes = {
  greeting: PropTypes.string
};
export default ControllerTunnelStatus
