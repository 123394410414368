import React from "react";
import _ from "lodash";
import classNames from "classnames";
import EntryExitTableRow from "./EntryExitTableRow.js";
import moment from "moment-timezone";
import { transformEntryMechanism, formatAccessCredential } from "Helper";

class TransitSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      transits: [],
      searching: false,
      credentialInput: '',
      plateInput: '',
      lastNameInput: '',
      time_zone: 'America/New_York',
      errorMessage: ""
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.updateTransitView = this.updateTransitView.bind(this);
  }

  handleCredentialChange = e => this.setState({...this.state, credentialInput: e.target.value});
  handlePlateChange = e => this.setState({...this.state, plateInput: e.target.value});
  handleLastNameChange = e => this.setState({...this.state, lastNameInput: e.target.value});

  componentDidMount() {
    const garageId = window.location.pathname.split("/").pop();

    fetch(`/garages/${garageId}.json`)
      .then(response => response.json())
      .then(data => {
        this.setState({...this.state, time_zone: data.garage.time_zone})
      });
  }

  buildParams() {
    let theParams = {
      api_key: process.env.query_microservice_key,
      query_type: "transit",
      garage_id: window.location.pathname.split("/").pop(),
      rfid: this.state.credentialInput,
      plate_number: this.state.plateInput,
      last_name: this.state.lastNameInput,
      target: this.getEnvironment()
    };


    return theParams;
  }

  async updateTransitView(garageId, transitId) {
    window.getTransit(garageId, transitId);
  }

  presentTransit(transit) {
    const timeStamps = this.presentTimeStamps(transit);
    var cleared_inventory_at = new Date(0)
    try {
      if ($('#cleared-inventory-container').attr("data")) {
        cleared_inventory_at = new Date($('#cleared-inventory-container').attr("data")*1000);
      }
    }
    catch(e) {
      console.log("Failed to read clear inventory using jQuery");
    }

    return({
      closed_transit: false,
      duration_in_time: timeStamps.duration,
      entry_mechanism: transformEntryMechanism(transit.raw_entry_mechanism),
      garage_id: transit.garage_id,
      transit_id: transit.id,
      local_entry_time: timeStamps.local_entry_time,
      local_exit_time: timeStamps.local_exit_time,
      payment: transit.payment,
      permit_busy_valid: null,
      transaction_id: transit.transaction_id,
      transit_id: transit.id,
      access_credential: formatAccessCredential(transit),
      vehicle_state_and_plate: this.presentStatePlate(transit),
      entered_before_cleared_inventory: new Date(transit.detected_at) < cleared_inventory_at      
    })
  }

  presentStatePlate(transit) {
    let statePlate = "";
    if (transit.vehicle_state && transit.vehicle_state !== "") {
      statePlate += `(${transit.vehicle_state}) `;
    }
    if (transit.vehicle_plate) {
      statePlate += transit.vehicle_plate;
    }

    return statePlate;
  }

  presentTimeStamps(transit) {
    let timeStamps = {
      local_entry_time: '',
      local_exit_time: '',
      duration: ''
    }
    const time_zone = this.state.time_zone;

    if (transit.transit_type === "entry") {
      timeStamps.local_entry_time = moment.tz(transit.detected_at, time_zone).format("MM/DD HH:mm:ss SSS");
    }

    if (transit.transit_type === "exit") {
      timeStamps.local_exit_time = moment.tz(transit.detected_at, time_zone).format("MM/DD HH:mm:ss SSS");

      if (transit.entry_transit_id) {
        // Find the transit and show it
        const entryTransit =  _.find(this.state.transits, function(t) {
          return t.id === transit.entry_transit_id
        });

        if (entryTransit) {
          timeStamps.local_entry_time = moment.tz(entryTransit.detected_at, time_zone).format("MM/DD HH:mm:ss SSS");

          const diff = moment(transit.detected_at).diff(entryTransit.detected_at);
          timeStamps.duration = moment.utc(diff).format("H:mm:s");
        }

      }
    }

    return timeStamps;
  }

  getEnvironment() {
    const hostname = location.hostname;

    if (hostname.includes("localhost") || hostname.includes("staging") || hostname.includes("test")) {
      return "staging";
    } else {
      return "production";
    }
  }

  // Dont show entries if it has an exit to prevent clutter/duplication
  // Currently done when rendering because we need the entries in state.transits to get their timestamps
  filterEntries(transits) {
    const fetchedTransits = transits;

    const filteredTransits = _.filter(fetchedTransits, function(transit) {
        if (transit.transit_type === "entry" && transit.exit_transit_id !== null) {
          return false;
        } else {
          return true;
        }
      });

    return filteredTransits;
  }

  async handleSearch(e) {
    e.preventDefault();

    this.setState({...this.state, searching: true}, () => {
      fetch(process.env.query_microservice_url, {
          method: "POST",
          body: JSON.stringify(this.buildParams())
        })
        .then((response) => {
          if (![200, 422].includes(response.status)) { throw Error(response.statusText); }
          return response;
        })
        .then(response => response.json())
        .then(data=> {
          if ('errorMessage' in data) {
            // Handle error
            this.setState({...this.state, errorMessage: data.errorMessage})
          } else {
            this.setState({...this.state, errorMessage: "", transits: data})
          }
        })
        .catch((error) => {
          console.error(error)
          this.setState({...this.state, errorMessage: error})
        })
        .finally(() => {
          this.setState({...this.state, searching: false})
        })
    })
  }

  render() {
    const { transits, errorMessage } = this.state;

    return (
      <>
        <div className="well">
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

          <p><strong>Transit Search</strong></p>

          <form className="form-inline">
            <div className="form-group">
              <label>Tag/Credential Id:</label>
              <input onChange={this.handleCredentialChange} type="text" name="credential" placeholder="XXXX XXXX" />
            </div>
            <div className="form-group">
              <label>Plate #:</label>
              <input onChange={this.handlePlateChange} type="text" name="vehicle_plate" placeholder="ABCDE" />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input onChange={this.handleLastNameChange} type="text" name="lastname" />
            </div>
            <button onClick={this.handleSearch} type="submit" disabled={this.state.searching}>
              { this.state.searching ? 
                <span>Searching...</span> :
                <span>Search</span>
              }
            </button>
          </form>
        </div>

        <table className="table table-hover" id="transit-view">
          <thead>
            <tr>
              <th>Date/Time In</th>
              <th>Date/Time Out</th>
              <th>Plate #</th>
              <th>Access</th>
              <th>Duration</th>
              <th>Fee</th>
              <th>Transit Type</th>
              <th>Transaction ID</th>
            </tr>
          </thead>
          <tbody className="transit-list" id="transit-list">
            {this.filterEntries(transits).map((originalTransit, _index) => {
              const transit = this.presentTransit(originalTransit);
              return (

              <EntryExitTableRow
                key={transit.transit_id}
                duration_in_time={transit.duration_in_time}
                entry_mechanism={transit.entry_mechanism}
                local_entry_time={transit.local_entry_time}
                local_exit_time={transit.local_exit_time}
                garage_id={transit.garage_id}
                payment={transit.payment}
                transaction_id={transit.transaction_id}
                transit_id={transit.transit_id}
                access_credential={transit.access_credential}
                vehicle_state_and_plate={transit.vehicle_state_and_plate}
                closed_transit={transit.closed_transit}
                permit_busy_valid={transit.permit_busy_valid}
                entered_before_cleared_inventory={transit.entered_before_cleared_inventory}
                clickHandler={this.updateTransitView}
              />
            )
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default TransitSearch;

