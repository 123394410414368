import React from "react"
import PropTypes from "prop-types"

class SuspiciousActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: null,
      isLoading: false,
      errorMessage: null,
      garageId: null,
      web_aws_access_key_id: props.web_aws_access_key_id,
      web_aws_secret_access_key: props.web_aws_secret_access_key,
      aws_env_name: props.aws_env_name,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    console.log("value: "+value);
    const garageId = value;
    this.fetchSuspiciousActivities(garageId);
  }

  padTime(s) {
    var s = Math.floor(s);
    if (s >= 10) {
      return ""+s;
    }
    return "0"+s;
  }

  formatTime(str) {
    if (str == null) {
      return "";
    }
    var date = new Date(str);
    var day = date.getDate();
    var month = date.getMonth()+1;
    return ""+month+"/"+day+" "+this.padTime(date.getHours())+":"+this.padTime(date.getMinutes())+":"+this.padTime(date.getSeconds());
  }

  render () {
    var header = "No suspicious activities";
    if (this.state.garageId && this.state.garageId != "") {
      header += " for the selected garage";
    }
    if (this.state.errorMessage) {
      header = this.state.errorMessage;
    }
    var options = [];
    let garages = this.state.report ? this.state.report.garages : [];
    console.log("rendering with garage count "+garages.length);
    for (var garageIndex = 0; garageIndex < garages.length; garageIndex++) {
      var selected = false;
      // TODO: fix this placeholder nonsense with an actual list of garages
      var garageId = garages[garageIndex].id;
      var garageName = garages[garageIndex].name;
      if (this.state.garageId == garageId) {
        selected = true;
      }
      options.push(
        <option key={garageId} value={garageId} defaultValue={selected}>{garageName}</option>
      )
    }
    const report_nav = (
        <p>Show report for: <select onChange={this.handleInputChange}>
          <option value="null">All Garages</option>
          {options}
        </select></p>
    )

    if (this.state.report == null) {
      const message = this.state.errorMessage || "Report not available"
      return (
        <React.Fragment>
        <div id="suspicious_activities" className={this.state.isLoading ? "loading" : "done-loading"}>
          <p>{message}.</p>
          {report_nav}
        </div>
        </React.Fragment>

      )
    }

    console.log("report: "+JSON.stringify(this.state.report));
    console.log("Rendering with report record count: "+this.state.report.records.length);

    var main_table = "";
    if (this.state.report.records.length > 0) {
      header = ""+this.state.report.records.length+" suspicious activities";

      var html_rows = [];
      for (var row = 0; row < this.state.report.records.length; row++) {
        var record = this.state.report.records[row];
        var html_row = (
            <tr key={row}>
            <td>{this.formatTime(record.recorded_at)}</td>
            <td>{record.garage_name}</td>
            <td>{record.reader_name}</td>
            <td>{record.firstname} {record.lastname}</td>
            <td>{record.credential}</td>
            <td>{record.plate}</td>
            <td>{record.activity}</td>
            </tr>
        );
        html_rows.push(html_row)
      }
      main_table = (
        <table className="main_table">
        <tbody>
            <tr>
            <th>Event Time</th>
            <th>Garage</th>
            <th>Reader</th>
            <th>Name</th>
            <th>Credential</th>
            <th>Plate</th>
            <th>Activity</th>
            </tr>
        {html_rows}
        </tbody>
        </table>
      );
    }
    return (
        <React.Fragment>
        <div id="suspicious_activity" className={this.state.isLoading ? "loading" : "done-loading"}>
        <h1>Suspicious Activity Report</h1>
        <div id="header">
        {report_nav}
        <p>{header}</p>
        </div>
        {main_table}
        </div>
        </React.Fragment>
      )

  }

  async fetchSuspiciousActivities(garageId) {
    console.log("Fetching with garageId: "+garageId);
    var url = this.props.baseUrl+"/api/v2/transits_reports/suspicious_activity?garage_id="+garageId
    this.setState({isLoading: true, errorMessage: null, garageId: garageId});
    try {
      const response = await fetch(url);
			if (response.status == 200) {
        console.log("good status back");
				const json = await response.json();
				if (json.errors && typeof json.errors === 'object' && json.errors.constructor === Array && json.errors.length > 0) {
					errorMessage = json.errors.join("; ");
					this.setState({report: null, isLoading: false, errorMessage: errorMessage});
					return Promise.reject(new Error(errorMessage))
				}
				else {
          console.log("setting report");
					this.setState({report: json, isLoading: false, errorMessage: null});
					return;
				}
			}
			else {
				var errorMessage = response.statusText;
				if (response.status == 404) {
					errorMessage = "Report not available";
				}
				this.setState({report: null, isLoading: false, errorMessage: errorMessage});
			}
    }
    catch (error) {
      console.log(error);
			this.setState({isLoading: false});
			const errorString = ""+error;
			this.setState({report: null, isLoading: false, errorMessage: ''+error});
    }
  }

  async componentDidMount() {
    await this.fetchSuspiciousActivities(null);
  }

}



SuspiciousActivity.propTypes = {
  greeting: PropTypes.string
};

export default SuspiciousActivity;