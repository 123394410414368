import React from "react";
import classNames from "classnames";

const EntryExitTableRow = ({
  local_entry_time,
  local_exit_time,
  vehicle_state_and_plate,
  access_credential,
  duration_in_time,
  payment,
  entry_mechanism,
  transaction_id,
  transit_id,
  garage_id,
  closed_transit,
  permit_busy_valid,
  entered_before_cleared_inventory,
  clickHandler
}) => (
  <tr
    className={classNames("transitrow", "transit-record", {
      "closed-transit": closed_transit
    })}
    data-transit-id={transit_id}
    onClick={() => clickHandler(garage_id, transit_id)}
  >
    <td className={entered_before_cleared_inventory ? "entry_time_before_inventory_clear" : "" }>{local_entry_time}</td>
    <td>{local_exit_time}</td>
    <td>{vehicle_state_and_plate}</td>
    <td>{access_credential}</td>
    <td>{duration_in_time}</td>
    <td>{payment}</td>
    <td className="transit-entry-td">
      {entry_mechanism}
      {permit_busy_valid && (
        <span
          className="glyphicon glyphicon-ok"
          style={{ color: "green", marginLeft: "4px" }}
        ></span>
      )}
    </td>
    <td>{transaction_id}</td>
  </tr>
);

export default EntryExitTableRow;
